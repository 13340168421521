import React from 'react'
import { Box } from '@mui/material'
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { createSessionFromToken } from './apiClient'
import { useContext } from 'react'
import { UserContext } from './App'

export function GoogleSignIn() {
  const userContext = useContext(UserContext)

  const handleGoogleLoginSuccess = async (googleCredentialResponse: CredentialResponse) => {
    try {
      console.log(`got credential response from google ${JSON.stringify(googleCredentialResponse)}`)
      const createSessionResponse = await createSessionFromToken(googleCredentialResponse)
      console.log(`got create session response`, createSessionResponse)
      userContext?.setUser(createSessionResponse)
    } catch (err) {
      console.log(`caught error creating session from token`, err)
      if (err instanceof Error) {
        userContext?.displayError(err.message)
      }
    }
  }

  return (
    <Box sx={{ padding:'1.0REM', margin:'1.0REM'}}>
      <GoogleOAuthProvider clientId={`780404328871-8bi6r1fc9jgvfioc6ajn1g5f5nds0vot.apps.googleusercontent.com`} >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.0REM',
          }}
        >
          <GoogleLogin
            type='standard'
            theme='filled_blue'
            size='medium'
            shape='rectangular'
            auto_select={true}
            onSuccess={handleGoogleLoginSuccess}
            onError={() => {
              console.log(`error in google signin`)
            }}
          />
        </Box>
      </GoogleOAuthProvider>
    </Box>
  )
}
