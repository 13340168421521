import { CredentialResponse } from '@react-oauth/google'
import { UserInfo } from './App'
import { getMockData } from './api-mock'

const freezemonAPIURL = process.env.REACT_APP_FREEZEMON_API_URL || `http://localhost:5000`

const appName = `freezemon`

export async function getLast24HourTemps() {
  try {
    if (process.env.REACT_APP_TEST === 'true') {
      return getMockData()
    } else {
      const response = await fetch(`${freezemonAPIURL}/${appName}/getRecentTemps?pastHours=24`, {
        method: 'GET',
        credentials: 'include',
      })

      // Check if response status is an error
      if (!response.ok) {
        // Handle HTTP errors
        const responseBody = await response.json()
        throw new Error(`HTTP Error: ${response.status} ${response.statusText} - ${responseBody.message || ''}`)
      }

      return response.json()
    }
  } catch (err) {
    console.error(err)

    // Distinguish between network and application errors for better error messaging
    if (err instanceof TypeError && err.message === 'Failed to fetch') {
      throw new Error('Network Error: Could not connect to the server.')
    }

    throw err
  }
}

export async function getCurrentUser() : Promise<UserInfo> {
  try {
    const response = await fetch(`${freezemonAPIURL}/${appName}/currentUser`, {
      method: 'GET',
      credentials: 'include',
    })

    // Check if response status is an error
    if (!response.ok) {
      // Handle HTTP errors
      const responseBody = await response.json()
      throw new Error(`HTTP Error: ${response.status} ${response.statusText} - ${responseBody.message || ''}`)
    }

    return response.json()
  } catch (err) {
    console.error(err)

    // Distinguish between network and application errors for better error messaging
    if (err instanceof TypeError && err.message === 'Failed to fetch') {
      throw new Error('Network Error: Could not connect to the server.')
    }

    throw err
  }
}

export async function createSessionFromToken(token: CredentialResponse): Promise<UserInfo> {
  try {
    const response = await fetch(`${freezemonAPIURL}/${appName}/authCallback`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(token),
    })

    // Check if response status is an error
    if (!response.ok) {
      // Handle HTTP errors
      const responseBody = await response.json()

      throw new Error(`HTTP Error: ${response.status} ${response.statusText} - ${responseBody.message || ''}`)
    }

    const responseJson = (await response.json()) as UserInfo
    console.log(responseJson)
    return responseJson
  } catch (err) {
    console.error(err)

    // Distinguish between network and application errors for better error messaging
    if (err instanceof TypeError && err.message === 'Failed to fetch') {
      throw new Error('Network Error: Could not connect to the server.')
    }

    throw err
  }
}
