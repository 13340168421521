import React from 'react'
import { Avatar, Box, IconButton, Typography } from '@mui/material'
import { createContext, useEffect, useState } from 'react'
import { FreezeMonMain } from './FreezeMonMain'
import { GoogleSignIn } from './GoogleSignIn'
import { getCurrentUser } from './apiClient'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import md5 from 'md5'

export interface UserInfo {
  name: string
  picture: string
  email: string
}
interface UserContextInterface {
  userInfo: UserInfo | undefined
  setUser: (user: UserInfo) => void
  displayError: (message: string) => void
}

export const UserContext = createContext<UserContextInterface | undefined>(undefined)

function App() : JSX.Element {
  const [currentUser, setCurrentUser] = useState<UserInfo | undefined>(undefined)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  async function fetchCurrentUser() : Promise<void> {
    console.log(`fetching current user`)
    try {
      const currentUserResponse = await getCurrentUser()
      console.log(`got current user response`, currentUserResponse)
      if (currentUserResponse.email) {
        console.log(`got current user`, currentUserResponse)
        setCurrentUser(currentUserResponse)
      } else {
        console.log(`no current user`)
        setCurrentUser(undefined)
      }
    } catch (err) {
      console.log(`caught error fetching current user`, err)
      if (err instanceof Error) {
        setErrorMessage(err.message)
        setErrorMessageOpen(true)
      } else {
        setErrorMessage(JSON.stringify(err))
        setErrorMessageOpen(true)
      }
    }
  }

  useEffect(() => {
    fetchCurrentUser()
  }, [])

  function welcomeOrLogin() : JSX.Element {
    console.log(`in welcomeOrLogin`, currentUser)
    if (currentUser) {
      return <FreezeMonMain />
    } else {
      return <GoogleSignIn />
    }
  }

  function handleClose() : void {
    setErrorMessageOpen(false)
  }

  function displayError(message: string) : void {
    setErrorMessage(message)
    setErrorMessageOpen(true)
  }

  function getGravatarUrl(email?: string) : string {
    if (!email) {
      return ''
    }
    const hash = md5(email.trim().toLowerCase())
    return `https://www.gravatar.com/avatar/${hash}?s=75`
  }

  return (
    <UserContext.Provider value={{ userInfo: currentUser, setUser: setCurrentUser, displayError }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Typography variant="h5" component="h4" sx={{ textAlign: 'left' }}>
          Office Freeze Monitor
        </Typography>

        <IconButton edge="end" color="inherit">
          <Avatar alt={currentUser?.name} src={getGravatarUrl(currentUser?.email)} />
        </IconButton>
      </Box>

      <Box>{welcomeOrLogin()}</Box>
      <Snackbar open={errorMessageOpen} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </UserContext.Provider>
  )
}

export default App
