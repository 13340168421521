import React, { useState, useEffect } from 'react'
import { TempRecord } from './FreezeMonMain'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { Box, Typography } from '@mui/material'

export interface FreezemonGraphProps {
  tempRecords: TempRecord[]
}

const formatXAxis = (tickItem: string) => {
  const date = new Date(tickItem)
  let hours = date.getHours()
  const minutes = date.getMinutes()
  // const seconds = date.getSeconds()
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12
  hours = hours ? hours : 12

  return `${hours}:${minutes} ${ampm}`
}

const formatDateForTitle = (dateString: string) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  let hours = date.getHours()
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12
  hours = hours ? hours : 12

  return `${month}-${day}-${year.toString().slice(-2)} at ${hours}${ampm}`
}

interface CustomTooltipProps {
  active?: boolean
  payload?: { value: number }[]
  label?: string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = new Date(label || '');
    const formattedDate = date.toLocaleTimeString();
    const roundedTemp = Math.round(payload[0].value * 10) / 10;

    return (
      <div
        style={{
          background: 'linear-gradient(145deg, #333, #222)', // Dark gradient background
          color: 'lightgray', // Light text color
          padding: '12px 16px',
          fontSize: '1.1rem',
          fontWeight: '500',
          fontFamily: 'sans-serif',
          boxShadow: '-4px -4px 10px #3a3a3a, 4px 4px 10px #0a0a0a', // Adjusted shadows for dark mode tactile effect
          borderRadius: '10px'
        }}
      >
        <p>{`Date: ${formattedDate}`}</p>
        <p>{`Temperature: ${roundedTemp}°F`}</p>
      </div>
    );
  }

  return null;
};





// eslint-disable-next-line
function CustomTick({ x, y, payload }: any) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="white" transform="rotate(-35)" style={{ fontFamily: 'sans-serif', fontSize: '0.8REM' }}>
        {formatXAxis(payload.value)}
      </text>
    </g>
  )
}

export function FreezemonGraph({ tempRecords }: FreezemonGraphProps) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      console.log('resize')
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const startDate = formatDateForTitle(tempRecords[0]?.timestamp || '')
  const endDate = formatDateForTitle(tempRecords[tempRecords.length - 1]?.timestamp || '')

  return (
    <Box>
      <Typography variant="h6">{`From ${startDate} to ${endDate}`}</Typography>
      <LineChart
        width={windowWidth - 20} // Giving it a little room on the right
        height={Math.max(windowHeight - 200, 275)} // Giving it a little room on the bottom
        data={tempRecords}
        margin={{ right: 20, left: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.8)" />
        <XAxis dataKey="timestamp" tick={<CustomTick />} tickFormatter={formatXAxis} angle={45} textAnchor="start" height={80} stroke={'lightgray'} />
        <YAxis domain={['auto', 'auto']} stroke="lightgray" label={{ value: 'ºF', angle: -90, position: 'insideLeft', fill: 'white' }} />
        <Tooltip content={<CustomTooltip />} />
        <Legend name={'foo'} />
        <Line name="Ceiling pipes temp ºF" type="monotone" dataKey="tempF" stroke="lightblue" activeDot={{ r: 8 }} />
      </LineChart>
    </Box>
  )
}
