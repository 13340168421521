import React, { useState, useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { UserContext } from './App'
import { FreezemonGraph } from './FreezemonGraph'
import { getLast24HourTemps } from './apiClient'
import PullToRefresh from 'react-simple-pull-to-refresh'

export interface TempRecord {
  timestamp: string
  tempF: number
}

export function FreezeMonMain() {
  const [tempRecords, setTempRecords] = useState<TempRecord[]>([])
  const userContext = useContext(UserContext)

  async function fetchLast24HourTemps() {
    console.log(`fetching last 24 hour temps`)
    const data = await getLast24HourTemps()
    setTempRecords(data)
  }

  useEffect(() => {
    try {
      fetchLast24HourTemps()
    } catch (err) {
      console.log(`caught error fetching last 24 hour temps`, err)
      if (err instanceof Error) {
        userContext?.displayError(err.message)
      }
    }
  }, [])
  return (
    <PullToRefresh onRefresh={fetchLast24HourTemps}>
      <Box>
        <FreezemonGraph tempRecords={tempRecords} />
      </Box>
    </PullToRefresh>
  )
}
