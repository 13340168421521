export function getMockData() {
  return Promise.resolve(mockData)
}

const mockData = [
  {
    _id: '653690bac027023a08c2cc60',
    timestamp: '2023-10-23T15:26:50.400Z',
    tempF: 60.6875,
  },
  {
    _id: '653692fd7854413b000ae800',
    timestamp: '2023-10-23T15:36:29.169Z',
    tempF: 60.6875,
  },
  {
    _id: '6536953c7854413b000ae801',
    timestamp: '2023-10-23T15:46:04.701Z',
    tempF: 60.91249847,
  },
  {
    _id: '65369780aa62cf84adab1b84',
    timestamp: '2023-10-23T15:55:44.075Z',
    tempF: 61.02500153,
  },
  {
    _id: '653699c2c751f11c7b92a058',
    timestamp: '2023-10-23T16:05:22.441Z',
    tempF: 61.25,
  },
  {
    _id: '65369c044ac4cce369d297ad',
    timestamp: '2023-10-23T16:15:00.785Z',
    tempF: 61.36249924,
  },
  {
    _id: '65369e47d0696e2dc276323b',
    timestamp: '2023-10-23T16:24:39.028Z',
    tempF: 61.58750153,
  },
  {
    _id: '6536a0894737f90e52fcab66',
    timestamp: '2023-10-23T16:34:17.467Z',
    tempF: 61.70000076,
  },
  {
    _id: '6536a2c94737f90e52fcab67',
    timestamp: '2023-10-23T16:43:53.022Z',
    tempF: 61.92499924,
  },
  {
    _id: '6536a50cfaba1ceacd697a4e',
    timestamp: '2023-10-23T16:53:32.235Z',
    tempF: 61.92499924,
  },
  {
    _id: '6536a74de06d5e15357a5b5f',
    timestamp: '2023-10-23T17:03:09.934Z',
    tempF: 62.03749847,
  },
  {
    _id: '6536a98f5baa569c549e0874',
    timestamp: '2023-10-23T17:12:47.795Z',
    tempF: 62.375,
  },
  {
    _id: '6536abd2dfe550580f6cf672',
    timestamp: '2023-10-23T17:22:26.015Z',
    tempF: 62.375,
  },
  {
    _id: '6536ae13bea4931f575f3980',
    timestamp: '2023-10-23T17:32:03.854Z',
    tempF: 62.71250153,
  },
  {
    _id: '6536b056373ef57639b7e625',
    timestamp: '2023-10-23T17:41:42.332Z',
    tempF: 62.82500076,
  },
  {
    _id: '6536b296373ef57639b7e626',
    timestamp: '2023-10-23T17:51:18.432Z',
    tempF: 62.9375,
  },
  {
    _id: '6536b4d9372deb6840232a3b',
    timestamp: '2023-10-23T18:00:57.764Z',
    tempF: 63.04999924,
  },
  {
    _id: '6536b71bdf00f9a4f2ebc851',
    timestamp: '2023-10-23T18:10:35.816Z',
    tempF: 63.27500153,
  },
  {
    _id: '6536b95dbb6f6e4e5ae20de6',
    timestamp: '2023-10-23T18:20:13.635Z',
    tempF: 63.38750076,
  },
  {
    _id: '6536bb9fb195b0778f774b60',
    timestamp: '2023-10-23T18:29:51.310Z',
    tempF: 63.5,
  },
  {
    _id: '6536bde161c273cec4a882c7',
    timestamp: '2023-10-23T18:39:29.417Z',
    tempF: 63.72499847,
  },
  {
    _id: '6536c023399ed461f4b6a6b8',
    timestamp: '2023-10-23T18:49:07.696Z',
    tempF: 63.83750153,
  },
  {
    _id: '6536c265e8137b9d1e217975',
    timestamp: '2023-10-23T18:58:45.750Z',
    tempF: 63.83750153,
  },
  {
    _id: '6536c4a7bdcc381857f061f5',
    timestamp: '2023-10-23T19:08:23.798Z',
    tempF: 63.95000076,
  },
  {
    _id: '6536c6e9cea0b9152af046e8',
    timestamp: '2023-10-23T19:18:01.655Z',
    tempF: 64.0625,
  },
  {
    _id: '6536c92c4ede5481377befe2',
    timestamp: '2023-10-23T19:27:40.132Z',
    tempF: 64.28749847,
  },
  {
    _id: '6536cb6e07dc68eff911d5b2',
    timestamp: '2023-10-23T19:37:18.275Z',
    tempF: 64.40000153,
  },
  {
    _id: '6536cdb0ca638486b86d1623',
    timestamp: '2023-10-23T19:46:56.304Z',
    tempF: 64.625,
  },
  {
    _id: '6536cff2ad8ac11fb4aae217',
    timestamp: '2023-10-23T19:56:34.823Z',
    tempF: 64.625,
  },
  {
    _id: '6536d235e5462db39e2d4430',
    timestamp: '2023-10-23T20:06:13.907Z',
    tempF: 64.73750305,
  },
  {
    _id: '6536d47772e3a1fb68e324eb',
    timestamp: '2023-10-23T20:15:51.667Z',
    tempF: 64.84999847,
  },
  {
    _id: '6536d6ba067525044341de2e',
    timestamp: '2023-10-23T20:25:30.151Z',
    tempF: 64.84999847,
  },
  {
    _id: '6536d8fbae5b107df20296e7',
    timestamp: '2023-10-23T20:35:07.806Z',
    tempF: 65.07499695,
  },
  {
    _id: '6536db3ebcfaa13c31565c69',
    timestamp: '2023-10-23T20:44:46.023Z',
    tempF: 65.1875,
  },
  {
    _id: '6536dd80405dfaeec08420ab',
    timestamp: '2023-10-23T20:54:24.589Z',
    tempF: 65.30000305,
  },
  {
    _id: '6536dfc3e663c9517918d479',
    timestamp: '2023-10-23T21:04:03.131Z',
    tempF: 65.30000305,
  },
  {
    _id: '6536e205dbb067f6ccb50446',
    timestamp: '2023-10-23T21:13:41.352Z',
    tempF: 65.41249847,
  },
  {
    _id: '6536e447deb8ea60d5a983e9',
    timestamp: '2023-10-23T21:23:19.739Z',
    tempF: 65.52500153,
  },
  {
    _id: '6536e68ace58fd67159e20d4',
    timestamp: '2023-10-23T21:32:58.090Z',
    tempF: 65.63749695,
  },
  {
    _id: '6536e8ccaaa4ca78330302c7',
    timestamp: '2023-10-23T21:42:36.702Z',
    tempF: 65.63749695,
  },
  {
    _id: '6536eb0ea2d29c54eaa2d93d',
    timestamp: '2023-10-23T21:52:14.825Z',
    tempF: 65.75,
  },
  {
    _id: '6536ed51d2a3cb6fbda08e16',
    timestamp: '2023-10-23T22:01:53.166Z',
    tempF: 65.86250305,
  },
  {
    _id: '6536ef9370ba8f676d2ee23f',
    timestamp: '2023-10-23T22:11:31.532Z',
    tempF: 65.86250305,
  },
  {
    _id: '6536f1d58dba57fa1b938cc6',
    timestamp: '2023-10-23T22:21:09.649Z',
    tempF: 66.08750153,
  },
  {
    _id: '6536f418cfe5729983683945',
    timestamp: '2023-10-23T22:30:48.182Z',
    tempF: 66.19999695,
  },
  {
    _id: '6536f65a21121bd221f95186',
    timestamp: '2023-10-23T22:40:26.258Z',
    tempF: 66.3125,
  },
  {
    _id: '6536f89c331d72447fd232d7',
    timestamp: '2023-10-23T22:50:04.308Z',
    tempF: 66.42500305,
  },
  {
    _id: '6536fade795238e8cc78036d',
    timestamp: '2023-10-23T22:59:42.514Z',
    tempF: 66.42500305,
  },
  {
    _id: '6536fd2040a907bf74b5e6fb',
    timestamp: '2023-10-23T23:09:20.816Z',
    tempF: 66.53749847,
  },
  {
    _id: '6536ff63acef5e54c7aaa5d2',
    timestamp: '2023-10-23T23:18:59.169Z',
    tempF: 66.65000153,
  },
  {
    _id: '653701a5b1fb561f20cb2626',
    timestamp: '2023-10-23T23:28:37.257Z',
    tempF: 66.65000153,
  },
  {
    _id: '653703e7ce31abd0c8d20e64',
    timestamp: '2023-10-23T23:38:15.746Z',
    tempF: 66.76249695,
  },
  {
    _id: '6537062a32c281110f319389',
    timestamp: '2023-10-23T23:47:54.108Z',
    tempF: 66.875,
  },
  {
    _id: '6537086cbe3b079cd2a43747',
    timestamp: '2023-10-23T23:57:32.000Z',
    tempF: 66.98750305,
  },
  {
    _id: '65370aae6c3a57b138659236',
    timestamp: '2023-10-24T00:07:10.808Z',
    tempF: 66.98750305,
  },
  {
    _id: '65370cf1d21c4533c17f144c',
    timestamp: '2023-10-24T00:16:49.193Z',
    tempF: 67.09999847,
  },
  {
    _id: '65370f349e61ae0cc72a5bfe',
    timestamp: '2023-10-24T00:26:28.349Z',
    tempF: 67.09999847,
  },
  {
    _id: '65371176b43df9309bed950e',
    timestamp: '2023-10-24T00:36:06.431Z',
    tempF: 67.09999847,
  },
  {
    _id: '653713b8271286fe9f363e54',
    timestamp: '2023-10-24T00:45:44.540Z',
    tempF: 67.21250153,
  },
  {
    _id: '653715fa27c603add6a3af68',
    timestamp: '2023-10-24T00:55:22.870Z',
    tempF: 67.21250153,
  },
  {
    _id: '6537183c01beab3ba1117a0a',
    timestamp: '2023-10-24T01:05:00.752Z',
    tempF: 67.21250153,
  },
  {
    _id: '65371a7f832f01da68bb0c29',
    timestamp: '2023-10-24T01:14:39.427Z',
    tempF: 67.32499695,
  },
  {
    _id: '65371cc1498511c55c62008e',
    timestamp: '2023-10-24T01:24:17.856Z',
    tempF: 67.32499695,
  },
  {
    _id: '65371f043ae8093807c7c36a',
    timestamp: '2023-10-24T01:33:56.369Z',
    tempF: 67.32499695,
  },
  {
    _id: '6537214676357cd4dcf06699',
    timestamp: '2023-10-24T01:43:34.537Z',
    tempF: 67.32499695,
  },
  {
    _id: '65372388b65a80590d13f906',
    timestamp: '2023-10-24T01:53:12.889Z',
    tempF: 67.32499695,
  },
  {
    _id: '653725cbf2596d6b8c560d96',
    timestamp: '2023-10-24T02:02:51.426Z',
    tempF: 67.32499695,
  },
  {
    _id: '6537280e439a7ea08f764a2c',
    timestamp: '2023-10-24T02:12:30.141Z',
    tempF: 67.32499695,
  },
  {
    _id: '65372a506df430506578ddfc',
    timestamp: '2023-10-24T02:22:08.756Z',
    tempF: 67.32499695,
  },
  {
    _id: '65372c93606e90f1ce6e4a30',
    timestamp: '2023-10-24T02:31:47.398Z',
    tempF: 67.32499695,
  },
  {
    _id: '65372ed30470097351566ad9',
    timestamp: '2023-10-24T02:41:23.694Z',
    tempF: 67.32499695,
  },
  {
    _id: '653731161c78862d96b22e69',
    timestamp: '2023-10-24T02:51:02.706Z',
    tempF: 67.32499695,
  },
  {
    _id: '65373359141de74a892e4b58',
    timestamp: '2023-10-24T03:00:41.719Z',
    tempF: 67.32499695,
  },
  {
    _id: '6537359bdd4546edc89a570e',
    timestamp: '2023-10-24T03:10:19.862Z',
    tempF: 67.32499695,
  },
  {
    _id: '653737de8efc9c8521829446',
    timestamp: '2023-10-24T03:19:58.609Z',
    tempF: 67.4375,
  },
  {
    _id: '65373a2196d0fdac9ce6d919',
    timestamp: '2023-10-24T03:29:37.224Z',
    tempF: 67.4375,
  },
  {
    _id: '65373c6323233ed665622b01',
    timestamp: '2023-10-24T03:39:15.685Z',
    tempF: 67.4375,
  },
  {
    _id: '65373ea6ea1e2803ef803cc9',
    timestamp: '2023-10-24T03:48:54.396Z',
    tempF: 67.55000305,
  },
  {
    _id: '653740e8de79dcf817faf64c',
    timestamp: '2023-10-24T03:58:32.285Z',
    tempF: 67.55000305,
  },
  {
    _id: '6537432a84f53a7ac83f66d1',
    timestamp: '2023-10-24T04:08:10.084Z',
    tempF: 67.55000305,
  },
  {
    _id: '6537456ca36d4eff68beaba5',
    timestamp: '2023-10-24T04:17:48.856Z',
    tempF: 67.55000305,
  },
  {
    _id: '653747af810e26823776b80d',
    timestamp: '2023-10-24T04:27:27.719Z',
    tempF: 67.55000305,
  },
  {
    _id: '653749f17dff447b973f7db6',
    timestamp: '2023-10-24T04:37:05.713Z',
    tempF: 67.66249847,
  },
  {
    _id: '65374c34a832f278a5bcaaaa',
    timestamp: '2023-10-24T04:46:44.083Z',
    tempF: 67.66249847,
  },
  {
    _id: '65374e76e86c816073398f7e',
    timestamp: '2023-10-24T04:56:22.866Z',
    tempF: 67.66249847,
  },
  {
    _id: '653750b864847bae788aba1e',
    timestamp: '2023-10-24T05:06:00.806Z',
    tempF: 67.66249847,
  },
  {
    _id: '653752fbf8cc0d43f50ee3cb',
    timestamp: '2023-10-24T05:15:39.020Z',
    tempF: 67.66249847,
  },
  {
    _id: '6537554136472ec92a6cd5fd',
    timestamp: '2023-10-24T05:25:21.480Z',
    tempF: 67.66249847,
  },
  {
    _id: '65375782179e07fe830bcf89',
    timestamp: '2023-10-24T05:34:58.648Z',
    tempF: 67.66249847,
  },
  {
    _id: '653759c49b0a44347c9eb355',
    timestamp: '2023-10-24T05:44:36.493Z',
    tempF: 67.66249847,
  },
  {
    _id: '65375c06955030d3fbc499ff',
    timestamp: '2023-10-24T05:54:14.425Z',
    tempF: 67.66249847,
  },
  {
    _id: '65375e4946b8931c2113029d',
    timestamp: '2023-10-24T06:03:53.270Z',
    tempF: 67.66249847,
  },
  {
    _id: '6537608bf304359fbc768fbe',
    timestamp: '2023-10-24T06:13:31.484Z',
    tempF: 67.77500153,
  },
  {
    _id: '653762cd2e22d28c341d68e7',
    timestamp: '2023-10-24T06:23:09.647Z',
    tempF: 67.66249847,
  },
  {
    _id: '6537650f10e92a3fdef77f75',
    timestamp: '2023-10-24T06:32:47.967Z',
    tempF: 67.66249847,
  },
  {
    _id: '65376751018fead21082efe5',
    timestamp: '2023-10-24T06:42:25.519Z',
    tempF: 67.66249847,
  },
  {
    _id: '653769939155faac49aff5f8',
    timestamp: '2023-10-24T06:52:03.984Z',
    tempF: 67.66249847,
  },
  {
    _id: '65376bd6e8a1f634a3269802',
    timestamp: '2023-10-24T07:01:42.731Z',
    tempF: 67.66249847,
  },
  {
    _id: '65376e181fd82c2ab0fa9310',
    timestamp: '2023-10-24T07:11:20.662Z',
    tempF: 67.66249847,
  },
  {
    _id: '6537705abedc9856cd020905',
    timestamp: '2023-10-24T07:20:58.885Z',
    tempF: 67.66249847,
  },
  {
    _id: '6537729d452bbdcd67253a15',
    timestamp: '2023-10-24T07:30:37.447Z',
    tempF: 67.55000305,
  },
  {
    _id: '653774dfdcf5caee86107403',
    timestamp: '2023-10-24T07:40:15.595Z',
    tempF: 67.55000305,
  },
  {
    _id: '65377721fa767fb6ad1109ad',
    timestamp: '2023-10-24T07:49:53.698Z',
    tempF: 67.55000305,
  },
  {
    _id: '6537796376f89a8c580d8496',
    timestamp: '2023-10-24T07:59:31.628Z',
    tempF: 67.4375,
  },
  {
    _id: '65377ba6fb01c0803140bd50',
    timestamp: '2023-10-24T08:09:10.413Z',
    tempF: 67.32499695,
  },
  {
    _id: '65377de90c3af6da5487a9b6',
    timestamp: '2023-10-24T08:18:49.034Z',
    tempF: 67.21250153,
  },
  {
    _id: '6537802b281c47ee4d442b96',
    timestamp: '2023-10-24T08:28:27.857Z',
    tempF: 67.09999847,
  },
  {
    _id: '65378275b9749513d300e576',
    timestamp: '2023-10-24T08:38:13.778Z',
    tempF: 66.98750305,
  },
  {
    _id: '653784b6f89482c7c09cb7e4',
    timestamp: '2023-10-24T08:47:50.363Z',
    tempF: 66.875,
  },
  {
    _id: '653786f8ccbad38d87e3b1ec',
    timestamp: '2023-10-24T08:57:28.658Z',
    tempF: 66.875,
  },
  {
    _id: '6537893b87d06e855ec75de3',
    timestamp: '2023-10-24T09:07:07.859Z',
    tempF: 66.76249695,
  },
  {
    _id: '65378b7e5b4157b2046b1000',
    timestamp: '2023-10-24T09:16:46.355Z',
    tempF: 66.65000153,
  },
  {
    _id: '65378dc061ebafa6dbfb4ad7',
    timestamp: '2023-10-24T09:26:24.872Z',
    tempF: 66.65000153,
  },
  {
    _id: '65379004c17e15a2ad6e82b1',
    timestamp: '2023-10-24T09:36:04.056Z',
    tempF: 66.42500305,
  },
  {
    _id: '65379246865aece3c1af5127',
    timestamp: '2023-10-24T09:45:42.106Z',
    tempF: 66.42500305,
  },
  {
    _id: '653794885b9602404adad096',
    timestamp: '2023-10-24T09:55:20.611Z',
    tempF: 66.3125,
  },
  {
    _id: '653796cafe17fb403168c6ab',
    timestamp: '2023-10-24T10:04:58.644Z',
    tempF: 66.19999695,
  },
  {
    _id: '6537990cda16ad9c6410b930',
    timestamp: '2023-10-24T10:14:36.972Z',
    tempF: 66.19999695,
  },
  {
    _id: '65379b4f60bef5b29f319067',
    timestamp: '2023-10-24T10:24:15.247Z',
    tempF: 66.08750153,
  },
  {
    _id: '65379d9162c5a32784f22c44',
    timestamp: '2023-10-24T10:33:53.800Z',
    tempF: 65.97499847,
  },
  {
    _id: '65379fd463ca5e01d75f5f87',
    timestamp: '2023-10-24T10:43:32.304Z',
    tempF: 65.86250305,
  },
  {
    _id: '6537a216ca2c1342b615a082',
    timestamp: '2023-10-24T10:53:10.522Z',
    tempF: 65.75,
  },
  {
    _id: '6537a45964ea6ce5cedc32d2',
    timestamp: '2023-10-24T11:02:49.126Z',
    tempF: 65.75,
  },
  {
    _id: '6537a69b571c9bb57cd2ab8f',
    timestamp: '2023-10-24T11:12:27.343Z',
    tempF: 65.63749695,
  },
  {
    _id: '6537a8dd264152236af79855',
    timestamp: '2023-10-24T11:22:05.219Z',
    tempF: 65.52500153,
  },
  {
    _id: '6537ab1f789d43c35ae975f3',
    timestamp: '2023-10-24T11:31:43.186Z',
    tempF: 65.52500153,
  },
  {
    _id: '6537ad61912ef2bad8ab2be6',
    timestamp: '2023-10-24T11:41:21.199Z',
    tempF: 65.41249847,
  },
  {
    _id: '6537afa3b9276715e6a270a0',
    timestamp: '2023-10-24T11:50:59.485Z',
    tempF: 65.30000305,
  },
  {
    _id: '6537b1e628600a2b73c05759',
    timestamp: '2023-10-24T12:00:38.067Z',
    tempF: 65.30000305,
  },
  {
    _id: '6537b42871ea805148108946',
    timestamp: '2023-10-24T12:10:16.290Z',
    tempF: 65.07499695,
  },
  {
    _id: '6537b66ef19e838099f71798',
    timestamp: '2023-10-24T12:19:58.198Z',
    tempF: 65.07499695,
  },
  {
    _id: '6537b8af27d04c8591b2f4ab',
    timestamp: '2023-10-24T12:29:35.436Z',
    tempF: 64.96250153,
  },
  {
    _id: '6537baf263ee8cd08d7db372',
    timestamp: '2023-10-24T12:39:14.033Z',
    tempF: 64.84999847,
  },
  {
    _id: '6537bd3432e90857abc88610',
    timestamp: '2023-10-24T12:48:52.344Z',
    tempF: 64.73750305,
  },
  {
    _id: '6537bf76e5d3ed38a50d1303',
    timestamp: '2023-10-24T12:58:30.760Z',
    tempF: 64.73750305,
  },
  {
    _id: '6537c1b95a1f56829d28a845',
    timestamp: '2023-10-24T13:08:09.263Z',
    tempF: 64.625,
  },
  {
    _id: '6537c3fbdd6ffe9e091fd84d',
    timestamp: '2023-10-24T13:17:47.209Z',
    tempF: 64.625,
  },
  {
    _id: '6537c63d4d64053a02deaaaa',
    timestamp: '2023-10-24T13:27:25.913Z',
    tempF: 64.51249695,
  },
  {
    _id: '6537c87f21366e7ecbba44c1',
    timestamp: '2023-10-24T13:37:03.859Z',
    tempF: 64.51249695,
  },
  {
    _id: '6537cac203065c804aa069a6',
    timestamp: '2023-10-24T13:46:42.035Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537cd032e23061c115b815d',
    timestamp: '2023-10-24T13:56:19.966Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537cf4e93c157028e116801',
    timestamp: '2023-10-24T14:06:06.119Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537d18e6a22fb64fedaef4e',
    timestamp: '2023-10-24T14:15:42.465Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537d3d0a08e3dcc7b409190',
    timestamp: '2023-10-24T14:25:20.626Z',
    tempF: 64.28749847,
  },
  {
    _id: '6537d61273ae1a533607f596',
    timestamp: '2023-10-24T14:34:58.914Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537d85585892008cdf563d3',
    timestamp: '2023-10-24T14:44:37.403Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537da988a6603931b53d84e',
    timestamp: '2023-10-24T14:54:16.293Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537dcdac60e5827661f4e79',
    timestamp: '2023-10-24T15:03:54.851Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537df1cd210d1d520bf1ac5',
    timestamp: '2023-10-24T15:13:32.729Z',
    tempF: 64.40000153,
  },
  {
    _id: '6537e15c91f3deafa868c703',
    timestamp: '2023-10-24T15:23:08.950Z',
    tempF: 64.51249695,
  },
]
